import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: '../pages/home/home.module#HomeComponentModule'
  },
  {
    path: 'sales',
    loadChildren: '../pages/sales/sales.module#SalesComponentModule'
  },
  {
    path: 'techniques/advertising',
    loadChildren: '../pages/techniques/advertising/advertising.module#AdvertisingComponentModule'
  },
  {
    path: 'techniques/chatbots',
    loadChildren: '../pages/techniques/chatbots/chatbots.module#ChatbotsComponentModule'
  },
  {
    path: 'techniques/contentmarketing',
    loadChildren: '../pages/techniques/contentmarketing/contentmarketing.module#ContentMarketingComponentModule'
  },
  {
    path: 'techniques/conversion',
    loadChildren: '../pages/techniques/conversion/conversion.module#ConversionComponentModule'
  },
  {
    path: 'techniques/data',
    loadChildren: '../pages/techniques/data/data.module#DataComponentModule'
  },
  {
    path: 'techniques/design',
    loadChildren: '../pages/techniques/design/design.module#DesignComponentModule'
  },
  {
    path: 'techniques/scraping',
    loadChildren: '../pages/techniques/scraping/scraping.module#ScrapingComponentModule'
  },
  {
    path: 'techniques/seo',
    loadChildren: '../pages/techniques/seo/seo.module#SeoComponentModule'
  },
  {
    path: 'techniques/socialmedia',
    loadChildren: '../pages/techniques/socialmedia/socialmedia.module#SocialMediaComponentModule'
  },
  {
    path: 'techniques/strategy',
    loadChildren: '../pages/techniques/strategy/strategy.module#StrategyComponentModule'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
