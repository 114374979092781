<nav class="navbar navbar-expand-lg navbar-light top-nav" id="navigation">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img src="assets/images/OU-Logo.svg" alt="">
    </a>
    <button (click)="toggleCollapse()" class="navbar-toggler" type="button"
            data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" [attr.aria-expanded]="collapse === 'open'? 'true': 'false'"
            aria-label="Toggle navigation">
      <img class="hamburger" src="assets/images/icons/_ionicons_svg_ios-menu.svg" alt="">
    </button>

    <div [ngClass]="collapse === 'open'? 'show': ''" class="collapse navbar-collapse " id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item top-nav--contact">
          <a class="nav-link" pageScroll [pageScrollDuration]="1000" [pageScrollEasing]="myEasing" href="#intro">Visie</a>
        </li>
        <li class="nav-item top-nav--contact">
          <a class="nav-link" pageScroll [pageScrollDuration]="2000" [pageScrollEasing]="myEasing" href="#takeoff">Contact</a>
        </li>
      </ul>
    </div>

  </div>
</nav>
