/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-page-scroll";
import * as i4 from "ngx-page-scroll-core";
import * as i5 from "@angular/router";
import * as i6 from "./navbar.component";
var styles_NavbarComponent = [i0.styles];
var RenderType_NavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "nav", [["class", "navbar navbar-expand-lg navbar-light top-nav"], ["id", "navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "navbar-brand"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/OU-Logo.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["aria-controls", "navbarSupportedContent"], ["aria-label", "Toggle navigation"], ["class", "navbar-toggler"], ["data-target", "#navbarSupportedContent"], ["data-toggle", "collapse"], ["type", "button"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCollapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["class", "hamburger"], ["src", "assets/images/icons/_ionicons_svg_ios-menu.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "collapse navbar-collapse "], ["id", "navbarSupportedContent"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 8, "ul", [["class", "navbar-nav ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "li", [["class", "nav-item top-nav--contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "nav-link"], ["href", "#intro"], ["pageScroll", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i3.NgxPageScrollDirective, [i4.PageScrollService, [2, i5.Router], i2.DOCUMENT], { href: [0, "href"], pageScrollDuration: [1, "pageScrollDuration"], pageScrollEasing: [2, "pageScrollEasing"], pageScroll: [3, "pageScroll"] }, null), (_l()(), i1.ɵted(-1, null, ["Visie"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "li", [["class", "nav-item top-nav--contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["class", "nav-link"], ["href", "#takeoff"], ["pageScroll", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i3.NgxPageScrollDirective, [i4.PageScrollService, [2, i5.Router], i2.DOCUMENT], { href: [0, "href"], pageScrollDuration: [1, "pageScrollDuration"], pageScrollEasing: [2, "pageScrollEasing"], pageScroll: [3, "pageScroll"] }, null), (_l()(), i1.ɵted(-1, null, ["Contact"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "collapse navbar-collapse "; var currVal_2 = ((_co.collapse === "open") ? "show" : ""); _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = "#intro"; var currVal_4 = 1000; var currVal_5 = _co.myEasing; var currVal_6 = ""; _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = "#takeoff"; var currVal_8 = 2000; var currVal_9 = _co.myEasing; var currVal_10 = ""; _ck(_v, 16, 0, currVal_7, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.collapse === "open") ? "true" : "false"); _ck(_v, 4, 0, currVal_0); }); }
export function View_NavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i1.ɵdid(1, 114688, null, 0, i6.NavbarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarComponentNgFactory = i1.ɵccf("app-navbar", i6.NavbarComponent, View_NavbarComponent_Host_0, {}, {}, []);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
