<section id="footer" class="bg-blue">
  <div class="container text-center text-md-left">
    <div class="row">
      <div class="col-lg-5 col-md-6 footer-list col-margin">
        <h5 class="text-uppercase">Orange Utang</h5>
        <span>Parallelweg 30</span><br />
        <span>5223 AL Den Bosch</span>
      </div>

      <div class="col-md-1 hide-md">
      </div>

      <div class="col-lg-2 col-md-6 footer-list col-margin">
        <h5 class="text-uppercase">Over ons</h5>
        <ul class="list-unstyled">
          <li>
            <a href="#">Over ons</a>
          </li>
          <li>
            <a href="#">Privacy</a>
          </li>
          <li>
            <a href="#">Voorwaarden</a>
          </li>
          <li>
            <a href="#">Sitemap</a>
          </li>
        </ul>
      </div>

      <!-- <div class="col-lg-2 col-md-6 footer-list col-margin-small">
        <h5 class="text-uppercase">Resources</h5>
        <ul class="list-unstyled">
          <li>
            <a href="#">Link 11</a>
          </li>
          <li>
            <a href="#">Link 2</a>
          </li>
          <li>
            <a href="#">Link 3</a>
          </li>
          <li>
            <a href="#">Link 4</a>
          </li>
        </ul>
      </div>

      <div class="col-lg-2 col-md-6 footer-list">
        <h5 class="text-uppercase">Updates</h5>
        <ul class="list-unstyled">
          <li>
            <a href="#">Link 1</a>
          </li>
          <li>
            <a href="#">Link 2</a>
          </li>
          <li>
            <a href="#">Link 3</a>
          </li>
          <li>
            <a href="#">Link 4</a>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</section>

<div class="footer-copyright py-3">
  <div class="container">
    <div class="row">
      <div class="col-9">
        <p class="white">© Orange Utang 2019</p>
      </div>
      <div class="col-3">
        <p class="text-right">
          <img class="hamburger" src="assets/images/icons/_ionicons_svg_logo-facebook.svg" alt="">
        </p>
      </div>
    </div>
  </div>
</div>
